<template>
  <ui-item class="ui-input-csv-column">
    <div class="ui-native">
      <template v-if="columnIndex !== null">
        <select
          v-model="columnIndex"
          @change="emitValue(columnIndex)"
        >
          <option
            v-for="(col,i) in records[0]"
            :key="i"
            :value="i"
          >Columna {{`${i+1}: ${col}`}}</option>
        </select>
      </template>
      <template v-else>
        <slot name="default"></slot>
      </template>
    </div>

    <template #right>
      <ui-icon
        v-if="columnIndex !== null"
        value="g:clear"
        @click="emitValue(null)"
      ></ui-icon>
      <ui-icon
        v-else
        value="g:playlist_add"
        @click="emitValue(0)"
        :disabled="!records.length"
      ></ui-icon>
    </template>
  </ui-item>
</template>

<script>
import { UiItem, UiIcon } from "@/modules/ui/components";

export default {
  name: "ui-input-csv-column",
  components: {
    UiItem,
    UiIcon
  },

  props: {
    records: {
      type: Array,
      required: false,
      default: () => []
    },

    value: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      columnIndex: this.value
    };
  },

  watch: {
    value(newValue) {
      this.columnIndex = newValue;
    }
  },

  methods: {
    emitValue(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss">
.ui-input-csv-column > .item-right {
  align-self: baseline;
  cursor: pointer;

  *[disabled] {
    pointer-events: none;
    opacity: .2;
  }
}
</style>